import React, { useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import Icon from 'components/ui-components-v2/Icon';
import Typography from 'components/ui-components-v2/Typography';

interface Props {
    title: string;
    children: ReactJSXElement;
    classes?: {
        root?: string;
        summaryRoot?: string;
        details?: string;
    };
    square?: boolean;
    expanded?: boolean;
    actions?: React.ReactNode; // The actions on the right of the header
}
const BrickAccordion: React.FC<Props> = ({ title, children, square, expanded = true, actions, classes }) => {
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(expanded);

    const handleChange = (expanded: boolean) => {
        setAccordionExpanded(expanded);
    };

    return (
        <Accordion
            expanded={accordionExpanded}
            square={square}
            disableGutters={square}
            className={classes?.root}
            onChange={(_e, expanded) => handleChange(expanded)}>
            <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
                <Typography variant="h5">{title}</Typography>
                <div>{actions}</div>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default BrickAccordion;
