import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Translation from 'components/data/Translation';
import Accordion from 'components/bricks/components/shared/components/accordion';
import AdPlacements from 'components/bricks/components/shared/components/ad-setup/components/ad-placements';
import useBrick from 'components/bricks/hooks/useBrick';
import Alert from 'components/ui-components-v2/Alert';
import useValidations from 'components/bricks/hooks/useValidations';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { BrickSetup } from 'components/bricks/types/brick.type';
import AdPlacementsHelpers from 'components/bricks/helpers/ad-placements.helpers';
import CreativesPreview from '../../creatives-preview';
import { AdSetupProvider } from '../context/ad-setup-context';
import TooltipContent from './alert-tooltip-content';
import AdSetupType from './ad-setup-type';

import './../styles/main.scss';

interface ComponentStoreProps {
    activeTab: BricksComponentStore['activeTab'];
}

const AdSetup = () => {
    const { activeTab } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: {
            activeTab: 'activeTab'
        }
    });
    const { brick } = useBrick();

    const brickSetup = React.useMemo(() => BrickHelpers.getBrickData<BrickSetup | undefined>(brick?.subType, 'setup'), [brick?.id]);

    const { errors, warnings } = useValidations(brick ? [brick.id] : [], activeTab?.key);

    if (!brick) return;

    // Get text for the alert
    const getAlertText = () => {
        let text = `This ad has`;
        if (errors?.length) {
            text = `${text} ${errors.length} error${errors.length > 1 ? 's' : ''} ${warnings?.length ? 'and ' : ''}`;
        }
        if (warnings?.length) {
            text = `${text} ${warnings.length} warning${warnings.length > 1 ? 's' : ''}`;
        }
        return text;
    };

    return (
        <AdSetupProvider>
            <div className="ad-setup">
                <div className="ad-setup__left">
                    <AdSetupType />
                    {brickSetup?.config?.adSetup?.hasAdPlacements && (
                        <>
                            {AdPlacementsHelpers.hasMoreThanOnePlacement(brick) ? (
                                <Accordion square title={Translation.get('adSetup.placements.title', 'bricks')}>
                                    <AdPlacements />
                                </Accordion>
                            ) : (
                                <AdPlacements />
                            )}
                        </>
                    )}
                </div>
                <div className="ad-setup__right">
                    <Tooltip
                        classes={{
                            tooltip: 'ad-setup__right__alert__tooltip'
                        }}
                        title={<TooltipContent errors={errors} warnings={warnings} />}
                        disableHoverListener={!errors?.length && !warnings?.length}>
                        <div>
                            {(!!errors?.length || !!warnings?.length) && (
                                <Alert className="ad-setup__right__alert" severity="error">
                                    {getAlertText()}
                                </Alert>
                            )}
                        </div>
                    </Tooltip>
                    <CreativesPreview activeTabKey={activeTab?.key} />
                </div>
            </div>
        </AdSetupProvider>
    );
};

export default AdSetup;
